<template>
  <div 
    v-expose="{id: '2-3-39', data: bindData}"
    :class="{
      'product-card__selling-proposition-rank': true,
      'product-card__selling-proposition-ranknew': rankingLabelWithoutIcon,
    }"
    :style="containerStyle"
  >
    <div 
      class="rank-main"
      :style="mainStyle"
    >
      <img 
        v-if="!rankingLabelWithoutIcon"
        class="rank-logo" 
        :src="icon" 
      />
      <span class="rank-title">{{ rankTitleText }}</span>
    </div>
    <span 
      v-if="composeIdText" 
      class="rank-sub"
    >{{ composeIdText }}</span>
  </div>
</template>

<script name="ProductCardSellingPropositionRank" setup>
import { computed, inject } from 'vue'

const item = inject('item', () => {})
const index = inject('index')
const config = inject('config', () => {})

const props = defineProps({
  rankingList: {
    type: Object,
    default() {
      return {}
    }
  }
})

const containerStyle = computed(() => {
  const { color = '' } = props?.rankingList.value ?? {}

  // TODO: 后续需要接入榜单背景颜色 字体颜色取CCC数据
  const containerStyle = {
    color
  }
  return containerStyle
})

const mainStyle = computed(() => {
  const { backgroundColor = '' } = props?.rankingList.value ?? {}

  // TODO: 后续需要接入榜单背景颜色 背景颜色取CCC数据
  return {
    backgroundColor
  }
})

const rankingLabelWithoutIcon = computed(() => config.rankingLabelWithoutIcon)
const icon = computed(() => props.rankingList?.icon ?? '')
const composeIdText = computed(() => props.rankingList?.composeIdText ?? '')
const bindData = computed(() => {
  const { contentCarrierId, rankingType, carrierSubType } = props?.rankingList ?? {}
  return {
    src_module: 'ranking_list_label',
    src_identifier: `ri=${carrierSubType}\`rn=${rankingType}\`ps=${index + 1}\`jc=${contentCarrierId}`,
    goods_id: item?.value?.goods_id ?? '',
  }
})
const rankTitleText = computed(() => {
  const { rankTypeText, score } = props.rankingList || {}
  return item?.value?.bffGoodsInfo ? rankTypeText : `#${score} ${rankTypeText}`
})

</script>

<style lang="less" scoped>
.product-card__selling-proposition-rank {
  .flexbox();
  .align-center();
  max-width: 100%;
  flex-wrap: nowrap;

  height: var(--selling-rank-height, 14px);
  
  font-size: 12px;

  & > span, .rank-main > * {
    margin-left: 4px;
    line-height: 1em;
  }

  .rank-main {
    flex-wrap: nowrap;
    .flexbox();
    .align-center();
    background: linear-gradient(90deg, #FFEDDC 25.19%, rgba(255, 249, 220, 0.20) 100%);
  }


  .rank-logo {
    width: 12px;
  }

  .rank-title {
    white-space: nowrap;
    font-weight: bold;
    color: #CC6E00;
  }
  

  .rank-sub {
    white-space: nowrap;
    color: var(--sui-color-gray-dark-3, #767676);
  }

  span:last-child {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.product-card__selling-proposition-ranknew {
  font-size: 11px;
  background: #FFFBEB;
  .rank-main {
    position: relative;
    padding: 0 4px 0 2px;
    border-radius: 6px 1px;
    background: #ffe8ae;
    height: var(--selling-rank-height, 14px);
  }
  .rank-title {
    color: #C77210;
    font-weight: 700;
  }
  .rank-sub {
    padding-right: 4px;
    color: #D58700;
    height: var(--selling-rank-height, 14px);
    line-height: var(--selling-rank-height, 14px);
  }
}
[mir='rtl'] {
  .product-card__selling-proposition-ranknew .rank-main {
    border-radius: 1px 6px;
  }
}
</style>
